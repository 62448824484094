.hst-landing {
  --bg-card: #fff;
  --text: #444;
  --aqua: #40BBF5;
  --aqua-light: #9CE9EC;
  --aqua-gradient: linear-gradient(to right, var(--aqua), var(--aqua-light));
  --grey: #788686;

  color: var(--text);
}

.hst-landing a,
.hst-landing a:active,
.hst-landing a:visited  {
  color: inherit;
  text-decoration: none;
}

.hst-landing a:hover {
  color: var(--aqua);
}

.hst-landing h3 {
  font-weight: normal;
  font-size: 1.7em;
  margin: 0 0 .5em;
}

.hst-landing h6 {
  font-weight: bold;
  font-size: 1.1em;
  margin: 0 0 .2em;
}
/* 
.hst-landing p {
  margin-top: 0;
  margin-bottom: .75em;
} */

.hst-landing button,
.hst-landing .hst-btn,
.hst-landing .hst-btn:active,
.hst-landing .hst-btn:visited {
  border: none;
  background: black;
  padding: 0.75em 1.5em;
  border-radius: 3em;
  color: #fff;
  font-weight: bold;
  font-size: 0.9em;
  cursor: pointer;
}

.hst-landing button.hst-btn-light,
.hst-landing .hst-btn-light,
.hst-landing .hst-btn-light:active,
.hst-landing .hst-btn-light:visited {
  border: none;
  background: white;
  padding: 0.75em 1.5em;
  border-radius: 3em;
  color: black;
  font-weight: bold;
  font-size: 0.9em;
  cursor: pointer;
}

.hst-landing button:hover,
.hst-landing .hst-btn:hover {
  background: var(--aqua-gradient);
  color: black;
}

.hst-mobile {
  display: none;
}

@media (max-width: 767px) {
  .hst-desktop {
    display: none;
  }

  .hst-mobile {
    display: block;
  }
}