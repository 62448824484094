.hst-lightboxBg {
  z-index: 200;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00172840;
  display: flex;
  align-items: center;
  justify-content: center;

  --lightbox-padding: 2em;
}

.hst-lightbox {
  position: relative;
  background: white;
  border-radius: var(--lightbox-padding);
  padding: var(--lightbox-padding);
  box-shadow: 0 .25em calc(var(--lightbox-padding) / 2) #00172820;
  pointer-events: all;
  width: 90%;
  max-width: 60em;
}

.hst-lightboxClose {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  line-height: 1em;
  width: 1em;
  height: 1em;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--grey);
  font-weight: bold;
}

.hst-lightboxClose:hover {
  color: var(--text);
}
