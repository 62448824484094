.hst-navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 1.5em 3em 1.5em 1em;
  background: white;
  font-weight: bold;
  text-transform: uppercase;
  color: #788686;
  box-shadow: 0 -2em 2em 2em #00172840;
}

.hst-navbarContents {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: center;
}

.hst-navbar .hst-navbarBurger {
  padding: 1em;
}

.hst-logo {
  width: 140px;
  max-width: 30vw;
  margin-right: auto;
}

.hst-navParent {
  display: inline;
  position: relative;
}

.hst-navDropdown {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  left: -.5em;
  background: white;
  border-radius: .5em;
  padding: .5em 1em 1em 1em;
  margin-left: .5em;
  transition: opacity .3s ease-in-out .2s, max-height 0s linear .5s;
}

.hst-navParent:hover .hst-navDropdown,
.hst-navDropdown:hover {
  opacity: 1;
  max-height: 80vh;
  transition: opacity .1s ease-in-out;
}

.hst-navDropdown a {
  display: block;
  width: 100%;
  padding: .25em .5em;
}

.hst-navLink {
  padding: .5em;
  margin-left: 1em;
}

.hst-navbar .hst-navbarContents a.active,
.hst-navbar .hst-navMenu a.active {
  color: #444;
}

.hst-navbar .hst-navbarContents a.active:hover,
.hst-navbar .hst-navMenu a.active:hover {
  color: #000;
}

.hst-login {
  margin-left: 2em;
  flex-shrink: 0;
}

.hst-navMenuContainer {
  position: absolute;
  left: 0em;
  width: 100%;
  height: 0;
  overflow: scroll;
  transition: height .2s ease-in-out;
}

.hst-navMenuContainer.shown {
  height: 25em;
}

.hst-navMenu {
  background: white;
  padding-bottom: 1em;
}

.hst-navMenu > * {
  display: block;
  width: 100%;
  padding: .5em 1.5em;
  font-size: 1.2em;
}

.hst-navMenu > *.hst-navIndent::before {
  content: '– '
}

.hst-navMenu > *.hst-navIndent {
  padding: .25em 1em .5em 2em;
}

@media(max-width: 1300px) {
  .hst-navbar {
      padding: 1.5em 3em 1.5em 3em;
    }
}

@media (max-width: 767px) {
  .hst-navbar {
    padding: .5em 1em .5em .5em;
    font-size: .9em;
  }

  .hst-logo {
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 160px;
    padding-left: 20px;
  }

  .hst-nav {
    display: none;
  }

  .hst-login {
    margin-left: 1em;
  }
}