.hstc-landingBtnX {
  padding: .5em 1em;
  border: 2px solid var(--text);
  margin: 0 .5em 1em;
  border-radius: 3em;
  font-weight: bold;
}

.hstc-topic {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1em 1em;
}

.hstc-topicBtn {
  width: 90px;
  height: 90px;
  border-radius: 180px;
  margin-bottom: .5em;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hstc-topicBtn img {
  width: 60px;
  height: 60px;
}

.hstc-footnote {
  vertical-align: super;
  font-size: .6em;
  font-weight: bold;
  cursor: pointer;
}

.hstc-impact {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.hstc-impact img {
  margin-right: .5em;
}

.hstc-trials {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; 
  max-width: 60em;
  margin: 0 auto;
}

.hstc-trials img {
  margin: 1em;
}

iframe.ns-embedded-form {
  height: 600px;
  outline: none;
  border: none;
}

@media (max-width: 767px) {
  .hstc-impact img {
    width: 30px;
    height: 30px;
  }

  .hstc-trials img {
    height: 75px;
    width: 75px;
    margin: 0;
  }
}
