/* Section */
.outer-width {
    max-width: 1680px;
    margin: 0 auto 2em;
    padding: 0 40px;
}

.section {
    display: flex;
}

.section.section-bg {
    box-shadow: 0 .25em 1.5em #00172820;
    background: white;
    border-radius: 2em;
    padding: 80px 0;
}

.section .section-text.section-bg {
    box-shadow: 0 .25em 1.5em #00172820;
    background: white;
    border-radius: 2em;
    padding: 80px 0;
    width: 47%;
    margin-left: auto;
    margin-right: inherit;
}

.section.img-right .section-text.section-bg {
    margin-right: auto;
    margin-left: inherit;
}

.section img {
    width: 100%; 
    height: auto;
    padding: 0 40px;
}

.section .section-image {
    width: 50%;
    display: flex;
    align-items: center;
}

.section.img-right .section-text {
    order: 1;
}

.section.img-right .section-image {
    order: 2;
}

.section .section-text {
    width: 50%;
    display: flex;
    align-items: center;
}

.section .section-text-inner {
    max-width: 500px;
    margin: auto;
    width: 100%;
    padding: 20px;
}

/* ImageSection */
.section .image-container {
    display: flex;
}

.section.image-section .title {
    font-weight: 700;
    text-align: center;
    margin-bottom: 25px;
    font-size: 26px;
}

.section.section-bg.mb-none {
    padding-bottom: 0;
}

.section img.block {
    display: block;
}

.section img.hide {
    display: none;
}

@media(max-width: 767px) {
    .outer-width {
        padding: 0 15px;
    }

    .section,
    .section.section-bg {
        padding: 20px 0;
        flex-wrap: wrap;
    }

    .section {
        box-shadow: 0 .25em 1.5em #00172820;
        background: white;
        border-radius: 2em;
    }

    .section .section-image,
    .section.img-right .section-image {
        width: 100%;
        order: 1;
        margin-bottom: 20px;
    }

    .section .section-text {
        width: 100%;
        order: 2;
    }

    .section .section-text.section-bg {
        width: 100%;
        padding: 10px 0;
        box-shadow: none;
        border-radius: none;
    }

    .section img.mbl-block {
        display: block;
    }

    .section img.mbl-hide {
        display: none;
    }
}
