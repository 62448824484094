.hst-hero {
  width: 100%;
  position: relative;
  margin-bottom: 2em;
}

.hst-heroImg {
  width: 100%;
}

.hst-heroContent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 13%;
  right: 55%;
  /* background: #FFAA0044; */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hst-heroContent-light {
  color: black;
}

.hst-heroContent-altLayout {
  top: 5%;
  bottom: 60%;
  left: 10%;
  right: 10%;
  align-items: center;
}

.hst-heroContent > * {
  margin: 0 0 .5rem;
}

.hst-heroSuper,
.hst-heroSub {
  font-size: 1.5rem;
}

.hst-heroTitle {
  font-size: 3rem;
  line-height: 1.2;
}

.hst-heroButtons {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.hst-heroButtons .hst-btn {
  display: flex;
  align-items: center;
  margin-right: 1em;
}

.hst-heroButtons img {
  margin: -.5em .25em -.5em -.5em;
  height: 2em;
  width: auto;
}

@media (max-width: 767px) {
  .hst-heroContent {
    top: 0;
    bottom: 50%;
    left: 2em;
    right: 1em;
    align-items: flex-start;
  }

  .hst-heroSuper,
  .hst-heroSub {
    font-size: 1.25rem;
  }

  .hst-heroTitle {
    font-size: 2.25rem;
  }
}
