.hst-videoBanner {
  max-width: 60em;
  margin: 0 auto 3em;
}

.hst-videoBannerFull {
  width: 100%;
  max-width: unset;
  margin: 0;
}

.hst-videoBanner img {
  width: 100%;
  cursor: pointer;
}
