.hst-tileGrid {
  width: 100%;
  max-width: 80em;
  margin: 0 auto;
  --tile-margin: 3em;
  --tile-padding: 2em;
}

@media (max-width: 767px) {
  .hst-tileGrid {
    --tile-margin: 1em;
    --tile-padding: 1.5em;
  }
}

.hst-tileRow {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  padding-left: var(--tile-margin);
  margin-bottom: var(--tile-margin);
}

.hst-dualTileFlip {
  order: 2;
}

/* ================ */

.hst-tile {
  margin-right: var(--tile-margin);
  width: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: var(--tile-padding);
  box-shadow: 0 .25em calc(var(--tile-margin) / 2) #00172820;
  background: white;
}

.hst-tile.hst-tileFlat {
  box-shadow: none;
  background: none;
  object-fit: contain;
}

img.hst-tile {
  padding: 0;
  object-fit: cover;
}

div.hst-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hst-imgMobile {
  width: 100%;
  border-radius: var(--tile-padding);
  margin-bottom: -1em;
}

.hst-tileContent {
  margin: var(--tile-padding) var(--tile-padding) calc(var(--tile-padding) / 2);
  display: flex;
  flex-direction: column;
}

.hst-tileCenter .hst-tileContent {
  align-items: center;
  text-align: center;
}

.hst-tileCenter .hst-tileContent .hbspt-form {
  align-self: stretch;
}

.hst-tileSuper {
  color: var(--aqua);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: .5em;
}

.hst-tileSuper a {
  text-decoration: underline !important;
}

.hst-fullP {
  max-width: 60em;
  margin: 0 auto .5em;
  text-align: center;
}

@media (max-width: 767px) {
  .hst-tileRow {
    flex-wrap: wrap;
  }

  .hst-tile {
    width: 100%;
    margin-bottom: var(--tile-margin);
  }
}
